import React, { useRef, useState } from 'react'

import { Hero, About, TechsIUse, GreatSoftware, ContinuousDelivery, Portfolio, UiuxPortfolio, CallToAction } from '../../sections/Index'
import './homepage.css'
import { Container, TestimonialCard, Footer } from '../../components'
import { FaX } from "react-icons/fa6";



const HomePage = () => {
  let [pointer, setPointer] = useState()
  let [ popup, setPopup] = useState(true)
  let divRef = useRef()
  return (
    <div className={popup? 'homepage popup-is-active' : 'homepage'}>
      <Hero/>

      <div className={popup? 'popup active' : 'popup'}>
        <div className='popup-box'>
          <FaX onClick={()=> setPopup(false)} className='close-popup'/>
          <h1>coming soon!</h1>
          <h2>under development</h2>
        </div>
      </div>
{/*       
      <div className='testimonial-section' >
          <Container >
            <div ref={divRef} onPointerMove={(e) => {setPointer(e.screenX - divRef.current.getBoundingClientRect().x); console.log(e); console.log(divRef.current.getBoundingClientRect().x)}}>
              <TestimonialCard order={1} pointer={pointer}/>
              <TestimonialCard order={2} pointer={pointer}/>
              <TestimonialCard order={3} pointer={pointer}/>
              <TestimonialCard order={4} pointer={pointer}/>
              <TestimonialCard order={5} pointer={pointer}/>
              <TestimonialCard order={6} pointer={pointer}/>
              
            </div>
          </Container>
      </div>

      <About/>
      <TechsIUse/>
      <GreatSoftware/>
      <ContinuousDelivery/>
      <Portfolio/>
      <UiuxPortfolio/> */}
      <CallToAction/>
      




      <Footer/>

    </div>
  )
}

export default HomePage
