import React from 'react'
import './hero.css'
import { Container } from '../../components'
import sidebar1 from '../../assets/hero-svg-1.png'
import sidebar2 from '../../assets/hero-svg-2.png'
import {HiCode} from 'react-icons/hi'
import {BsPlay} from 'react-icons/bs'
import {FaTiktok} from 'react-icons/fa'




import {AiFillGithub, AiFillLinkedin, AiFillYoutube, AiOutlineInstagram} from 'react-icons/ai'



const Hero = () => {
  return (
    <>
      <div className='homepage-hero'>
          <Container>
              <div className='inner-container'>
                <div className='left extra'>
                  <img src={sidebar1} alt="" />
                </div>
                <div className='content'>
                  <h1>Software Developer, Ui/Ux Designer</h1>
                  <p>
                    If you're looking for a software developer and/or Ui/Ux designer who can help take your business to the next level, contact me today.
                    Let's work together to build something amazing.
                  </p>
                </div>
              </div>
              <div className='inner-container'>
                <div className='left icon'>
                  <HiCode className='i'/>
                </div>
                <div className='content call-to-action'>
                  <button>hire me</button>
                  <button>see my work</button>
                </div>
              </div>
              <div className='inner-container sml'>
                <div className='left play'>
                  <img src={sidebar2} alt="" />
                  <div className='play-button'>
                    <BsPlay className='i'/>
                  </div>
                </div>
                <div className='content sml'>
                  <ul className='social-links'>
                    <li><a href="github.com/seif0912"><AiFillGithub className='i'/></a></li>
                    <li><a href="https://www.linkedin.com/in/seif-badreddine-0412791b3/"><AiFillLinkedin className='i'/></a></li>
                    <li><a href="https://www.youtube.com/channel/UCHIgT5ysROCDG54rhdCAAag"><AiFillYoutube className='i'/></a></li>
                    <li><a href="https://www.tiktok.com/@seifbadredine"><FaTiktok className='i'/></a></li>
                    <li><a href="https://www.instagram.com/seif_badreddine/"><AiOutlineInstagram className='i'/></a></li>
                  </ul>
                </div>
              </div>
          </Container>
      </div>
      
     </>
  )
}

export default Hero
