import React from 'react'
import './footer.css'
import Container from '../container/Container'
import { AiFillGithub, AiFillLinkedin, AiFillYoutube, AiOutlineInstagram } from 'react-icons/ai'
import { FaTiktok } from 'react-icons/fa'


const Footer = () => {
  return (
    <footer>
        <div className='main-footer'>
            <Container>
                <div className='block'>
                    <h2 className='text-logo'>seif badreddine</h2>
                    <p>
                    As a software developer and UI/UX designer, I specialize in creating functional and visually appealing digital experiences.
                    </p>
                  <ul className='social-links'>
                    <li><a href="github.com/seif0912"><AiFillGithub className='i'/></a></li>
                    <li><a href="https://www.linkedin.com/in/seif-badreddine-0412791b3/"><AiFillLinkedin className='i'/></a></li>
                    <li><a href="https://www.youtube.com/channel/UCHIgT5ysROCDG54rhdCAAag"><AiFillYoutube className='i'/></a></li>
                    <li><a href="https://www.tiktok.com/@seifbadredine"><FaTiktok className='i'/></a></li>
                    <li><a href="https://www.instagram.com/seif_badreddine/"><AiOutlineInstagram className='i'/></a></li>
                  </ul>
                </div>
                <div className='block'>
                    <h3>services</h3>
                    <ul>
                        <li>web development</li>
                        <li>ui/ux design</li>
                        <li>e-commerce solutions</li>
                        <li>Custom software solutions</li>
                        <li>CMS</li>
                    </ul>
                </div>
                {/* <div className='block'>
                    <h3>specialized solutions</h3>
                    <ul>
                        <li><a href="/">nichewebsite.com</a></li>
                        <li><a href="/">codesnippets.com</a></li>
                        <li><a href="/">dariii.tn</a></li>
                    </ul>
                </div> */}
            </Container>
        </div>
        <div className='footer-bottom'>
            <p>
                Copyright &copy; 2024 Seif Badreddine. All rights reserved.
            </p>
        </div>
    </footer>
  )
}

export default Footer
