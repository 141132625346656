import React from 'react'
import { Container } from '../../components'
import './callToAction.css'

const CallToAction = () => {
  return (
    <div className='call-to-action-section'>
        <div className='cta-background'></div>
        <Container>
          <h1><span>Let's work together<br/>to bring your vision to life!</span></h1>
          <p>
            <span>Don't wait any longer to take advantage of my services. Contact me today to schedule a consultation and see how I can help you achieve your business goals. I'm confident that you'll be thrilled with the results!</span>
          </p>
          <div className='content call-to-action'>
            <button>hire me</button>
            <button>contact me</button>
          </div>
          {/* <video width="320" height="240" >
            <source src="../../assets/planet-25550.mp4" src="../../assets/planet-25550.mp4" type="video/mp4"/>
          Your browser does not support the video tag.
          </video> */}
        </Container>
      </div>
  )
}

export default CallToAction