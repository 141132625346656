import React, { useEffect, useState } from 'react'
import { Container } from '..'
import './header.css'
import { BiSearchAlt2 } from 'react-icons/bi';
import { Link } from 'react-router-dom';

// import { Link } from 'react-router-dom'

const Header = () => {

    let [toggleclick, setToggleClick] = useState(false)
    useEffect(() => {
        function handleResize() {
          // console.log('resized to: ', window.innerWidth, 'x', window.innerHeight)
          if(window.innerWidth > 1200){
            setToggleClick(false)
          }
        }
    
        window.addEventListener('resize', handleResize)
      })

    return (
        <header>
            <Container>
                <h1 className='text-logo'><Link to='/'>seif badreddine</Link></h1>
                <div onClick={()=>{setToggleClick(prev => !prev)}} className={`burger ${toggleclick ? 'active' : ''}`}>
                    <span></span>
                    <span></span>
                    <span></span>
                </div>
                <nav className={toggleclick ? 'active' : ''}>
                    <div className='part left-part'>
                        <ul>
                            <li className='not-working'>about</li>
                            {/* <li><Link to='/services'>services</Link></li> */}
                            <li className='not-working'><a>services</a></li>
                            <li><a href='https://blog.seifbadreddine.com'>blog</a></li>
                        </ul>
                    </div>
                    <div className='part right-part'>
                        <form>
                            <input type="text" placeholder='search'/>
                            <button><BiSearchAlt2/></button>
                        </form>
                        {/* <Link to={'/'}>resume</Link> */}
                        <div>
                            <a href='/' className='resume-link' >resume</a>
                            <button className='contact-btn'>contact</button>
                        </div>
                    </div>
                </nav>
            </Container>
        </header>
    )
}

export default Header
