import { Route, BrowserRouter as Router, Routes } from 'react-router-dom';
import './App.css';
import { HomePage, ServicesPage } from './pages/Index';
import { Header } from './components';


function App() {
  return (
   <>
    <Router>
      <Header/>
      <Routes>
        <Route exact path='/' element={<HomePage/>} />
        {/* <Route exact path='/services' element={<ServicesPage/>} /> */}
      </Routes>
    </Router>
   </>
  );
}

export default App;
